/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { httpGet, httpUrl } from "../../api/httpclient";
import string_kr from "../../string_kr";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { NaverMap, Marker } from "react-naver-maps";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        x: 0,
        y: 0
      },
      address: ""
    };
  }

  componentDidMount() {
    const navermaps = window.naver.maps;

    let center = "37.4828880, 127.0205103".split(", ");
    this.setState(
      {
        center: {
          x: parseFloat(center[0]),
          y: parseFloat(center[1])
        }
      },
      () => {
        console.log(`center x: ${center[0]} / center y: ${center[1]}`);
        navermaps.Service.reverseGeocode(
          {
            location: new navermaps.LatLng(
              this.state.center.x,
              this.state.center.y
            )
          },
          (status, res) => {
            if (status !== navermaps.Service.Status.OK) {
              return alert("Something Wrong");
            }

            const result = res.result;
            console.log(`result: ${JSON.stringify(result, null, 4)}`);
            const items = result.items;
            this.setState({
              address: items[1].address
            });
          }
        );
      }
    );
  }

  render() {
    const navermaps = window.naver.maps;

    return (
      <section>
        <div style={{ backgroundColor: "rgba(0,0,0,0.68)" }}>
          <div className="container">
            <div className="title-text">회사정보 Company Information</div>
            <div className="subtitle-text">블랍스 소개</div>
            <div style={{ marginTop: "10px", marginLeft: "-8px" }}>
              <AnchorLink href="#blobs">
                <div
                  className="button-white"
                  style={{
                    marginTop: "10px",
                    marginLeft: "8px",
                    marginRight: "8px"
                  }}>
                  블랍스
                </div>
              </AnchorLink>
              <AnchorLink href="#vision">
                <div
                  className="button-white"
                  style={{
                    marginTop: "10px",
                    marginLeft: "8px",
                    marginRight: "8px"
                  }}>
                  비전
                </div>
              </AnchorLink>
              <AnchorLink href="#history">
                <div
                  className="button-white"
                  style={{
                    marginTop: "10px",
                    marginLeft: "8px",
                    marginRight: "8px"
                  }}>
                  연혁
                </div>
              </AnchorLink>
              <AnchorLink href="#location">
                <div
                  className="button-white"
                  style={{
                    marginTop: "10px",
                    marginLeft: "8px",
                    marginRight: "8px"
                  }}>
                  사업장
                </div>
              </AnchorLink>
            </div>

            {/* 블랍스 */}
            <section id="blobs">
              <div className="content-title-text-white">블랍스</div>
              <div className="content-line-blue"></div>
              <div className="content-image desk">
                <img
                  src={require("../../img/main/companyinfo/content-01.png")}
                />
              </div>
              <div className="content-image mobile">
                <img
                  src={require("../../img/main/companyinfo/content-01-m.png")}
                />
              </div>
              <div className="content-text-white">
                ㈜블랍스는 2018년 블록체인 기술을 기반으로 하는 ICT회사로
                설립되었습니다.
                <br />
                창립멤버를 위주로 설립 이전부터 일여년간 블록체인 기술에 대한
                연구개발을 진행하였으며, 설립과 동시에 최초 제품인 블랍스
                하이브리드 콜드월렛을 출시하였습니다.
                <br />
                지갑개발을 통해 확보한 DAPP 기술 및 솔루션을 기반으로 금융,
                의류, 물류, 친환경 등 다양한 업종 영역에서 기업들과 협업하여
                블록체인 기반의 혁신을 주도하였으며 현재는 기존 블록체인 기반의
                암호화폐의 한계를 극복할 수 있는 “플랫타＂라는 건전한 암호화폐
                생태계를 준비하고 있습니다. <br />
                <br />
                현재 암호화폐를 통해 양적으로만 팽창하고 있는 블록체인 분야에서
                화폐시장에 국한되지 않고 범용적으로 활용 가능한 블록체인
                플랫폼을 구축하여 다양한 산업영역으로 블록체인 기술을 확장하고
                나아가 글로벌 시장에서 4차 산업혁명을 견인하는 일류 기업이 되는
                비전을 가지고 있습니다.
              </div>
              <div style={{ height: "50px" }}></div>
            </section>
          </div>
        </div>
        <div className="vision-wrapper">
          <div className="container" style={{ paddingBottom: "100px" }}>
            {/* 비전 */}
            <section id="vision">
              <div>
                <div className="content-title-text-white">비전</div>
                <div className="content-line"></div>
                <div style={{ height: "30px" }}></div>
                <div className="content-text-white">
                  블록체인 영역에서 블랍스는 기존에 암호화폐와 관련된 영역에
                  집중하여 사업과 연구를 진행해 왔으나 중국과 메인넷 공동개발을
                  추진하면서 블랍스 플랫폼을 구축하여 2020년 부터는 산업영역,
                  보안기술, 국책사업 등으로 그 영역을 확장해 나갈 예정입니다.
                </div>
                <div className="content-image desk">
                  <img
                    src={require("../../img/main/companyinfo/content-02.png")}
                  />
                </div>
                <div className="content-image mobile">
                  <img
                    src={require("../../img/main/companyinfo/content-02-m.png")}
                  />
                </div>
                <div className="content-image desk">
                  <img
                    src={require("../../img/main/companyinfo/content-03.png")}
                  />
                </div>
                <div className="content-image mobile">
                  <img
                    src={require("../../img/main/companyinfo/content-03-m.png")}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="container" style={{ paddingBottom: "100px" }}>
          {/* 연혁 */}
          <section id="history">
            <div>
              <div className="content-title-text">연혁</div>
              <div className="content-line"></div>
              <div style={{ height: "30px" }}></div>
              <div className="content-image desk">
                <img
                  src={require("../../img/main/companyinfo/content-04.png")}
                />
              </div>
              <div className="content-image mobile">
                <img
                  src={require("../../img/main/companyinfo/content-04-m.png")}
                />
              </div>
            </div>
          </section>

          {/* 사업장 */}
          <section id="location">
            <>
              <div className="content-title-text">사업장</div>
              <div className="content-line"></div>
              <div className="location">
                <div className="location-info">
                  <div className="location-info-name">블랍스 BLOBS</div>
                  <div className="location-info-address">
                    <span>Office&nbsp;</span>
                    {/* <br className="mobile" />  */}
                    <div>서울시 서초구 남부순환로 2469 도요빌딩 5층</div>
                  </div>
                  <div className="location-info-email">
                    <span>E-mail</span> admin@blobs.kr
                  </div>
                  <div className="location-info-tel">
                    <span>Tel</span> 02-423-4261
                  </div>
                  <div className="location-info-site">
                    <span>Website</span> http://blobs.kr
                  </div>
                </div>
                <div className="location-map">
                  <NaverMap
                    mapDivId={"react-naver-map"} // default: react-naver-map
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                    defaultCenter={{
                      lat: "37.4828880",
                      lng: "127.0205103"
                    }}
                    defaultZoom={16}>
                    <Marker
                      title={"서울시 서초구 남부순환로 2469 도요빌딩 5층"}
                      position={
                        new navermaps.LatLng("37.4828880", "127.0205103")
                      }
                      events={["click", "mouseover"]}
                      onClick={() => {
                        var newWindow = window.open("about:blank");
                        newWindow.location.href =
                          "https://map.naver.com/v5/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%9C%EC%B4%88%EA%B5%AC%20%EB%82%A8%EB%B6%80%EC%88%9C%ED%99%98%EB%A1%9C%202469/address/14139865.595685396,4506640.583480731,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%9C%EC%B4%88%EA%B5%AC%20%EB%82%A8%EB%B6%80%EC%88%9C%ED%99%98%EB%A1%9C%202469,new?c=14139830.1624691,4506646.2993680,19,0,0,0,dh";
                      }}
                    />
                  </NaverMap>
                </div>
              </div>
            </>
          </section>
        </div>
        <div className="page-top-image-wrapper">
          <a href="#">
            <img
              src={require("../../img/icon/page-up.png")}
              className="page-top-image"
            />
          </a>
        </div>
      </section>
    );
  }
}
