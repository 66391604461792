const string_kr = {

  /******공통******/
  readyTo: "준비중 입니다.",

  /******네비게이션******/
  menuOffering: "오퍼링",
  menuNewsRoom: "뉴스룸",
  menuCompanyInfo: "회사정보",
  menuLink: "링크",
  menuLink1: "블랍스 카드",
  menuLink2: "블랍스 솔루션",
  menuLink6: "블랍스 코드",
  menuLink3: "블랍스 코인",
  menuLink4: "플랫타",
  menuLink5: "플랫타 거래소",

  /******오퍼링******/

  /******footer******/
  footerTitle: "BLOBS Coin",
  company: "(주) 블랍스",
  ceo: "대표 김민우",
  email: "Email: support@blobs.kr",
  address: "서울 강남구 영동대로 647 푸르지오시티 2층",
  businessRegistration: "사업자등록번호 883-88-01187",
  businessRegistration2: "통신판매업신고 2018-서울송파-2167",
  cs: "고객센터 : 02-423-4261(운영시간 : 10:00~18:00)"
};

export default string_kr;
