import React, { Component } from "react";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import Navigation from "./Navigation";

class Header extends Component {
  render() {
    return (
      <header id="header">
        <div className="navigation-wrapper">
          <div className="container">
            <Navigation />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
