export default {
  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "9002",
  // serverContext: "common"

  serverProtocol: "https",
  serverIp: "back.blobs.kr",
  serverPort: "9002",
  serverContext: "common"
};
