import React, { Component } from "react";
import { Link } from "react-router-dom";
import { httpGet, httpUrl } from "../../api/httpclient";
import string_kr from "../../string_kr";
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default class Home extends Component {
  render() {
    return (
      <section id='top'>
        <div style={{height: '300px'}}>
          <div className="container">
            <div className="title-text">
              오퍼링 Offering
            </div>
            <div className="subtitle-text">
              블랍스 주요 사업군
            </div>
            <div style={{marginTop: '10px', marginLeft: '-8px'}}>
              <AnchorLink href='#platform'>
                <div className="button-white" style={{marginTop: '10px', marginLeft: '8px', marginRight: '8px'}}>
                  블록체인 플랫폼 개발
                </div>
              </AnchorLink>
              <AnchorLink href='#solution'>
                <div className="button-white" style={{marginTop: '10px', marginLeft: '8px', marginRight: '8px'}}>
                  블록체인 솔루션 판매
                </div>
              </AnchorLink>
              <AnchorLink href='#coldwallet'>
                <div className="button-white" style={{marginTop: '10px', marginLeft: '8px', marginRight: '8px'}}>
                  블록체인 콜드월렛 서비스
                </div>
              </AnchorLink>
              <AnchorLink href='#service'>
                <div className="button-white" style={{marginTop: '10px', marginLeft: '8px', marginRight: '8px'}}>
                  블록체인 서비스 운영
                </div>
              </AnchorLink>
              <AnchorLink href='#flata-project'>
                <div className="button-white" style={{marginTop: '10px', marginLeft: '8px', marginRight: '8px'}}>
                  플랫타 프로젝트
                </div>
              </AnchorLink>
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="container" style={{minHeight: '600px', paddingTop: '10px', paddingBottom: '150px'}}>

            {/* 블록체인 플랫폼 개발 */}
            <section id='platform'>
              <div className="content-title-text">블록체인 플랫폼 개발</div>
              <div className="content-line"></div>
              <div className="content-summary-text">
                블랍스 플랫폼은 <br/>
                다양한 재단들이 쉽게 블록체인 기술을 적용할 수 있도록 인프라를 제공하는 것이 목표이다.
              </div>
              <div className="content-text">
                블랍스 플랫폼 버전 2.0에서는 토큰 지갑, 계약 관리, 에스크로, 위변조 보안 이렇게 총 4개의 서비스가 탑재 되어질 예정이다. <br/>
                각 서비스는 내부적으로 MSA(Micro Service Architecture)로 구성되어 외부에서 REST API를 통해 접근할 수 있는 방법을 제공하며, 
                블랍스에서 자체 제공하는 DAPP 라이브러리를 사용하는 경우 보다 쉽게 플랫폼 이용이 가능하다.
              </div>
              <div className="content-image desk">
                <img src={require('../../img/main/offering/content-01.png')}/>
              </div>
              <div className="content-image mobile">
                <img src={require('../../img/main/offering/content-01-m.png')}/>
              </div>
              <div className="content-text">
                블랍스 플랫폼이 추구하는 목표는 다양한 영역의 사용자들이 저렴한 비용으로 쉽고 빠르게 블록체인 서비스를 이용할 수 있는 환경을 만드는 것이다. <br/>
                이를 위해서는 소액의 거래, 빈번한 원장작성 등을 원활하게 처리하는 것이 무엇보다 중요하다. <br/>
                하지만 현재 블랍스 플랫폼은 이더리움의 스마트 컨트랙트를 블록체인 기반으로 사용하고 있기 때문에 수수료의 부담이 크고, 대량의 
                트랜잭션들이 빠르게 처리될 수 있는 구조를 가지기에는 한계가 있다. 하여 블랍스에서는 2020년까지 메인넷을 개발하여 블록체인 기반을 전환할 계획을
                가지고 있다. 메인넷과 관련된 상세 내용에 대해서는 백서 2.0에서 상세하게 다룰 예정이다.
              </div>
            </section>

            {/* 블록체인 솔루션 판매 */}
            <section id='solution'>
              <div style={{height: '50px'}}></div>
              <div className="content-title-text">블록체인 솔루션 판매</div>
              <div className="content-line"></div>
              <div style={{height: '20px'}}></div>
              <div className="content-text">
                블랍스는 자체 개발한 DAPP기반 솔루션을 통해 다양한 기업들의 요구사항에 맞게 커스터마이징하여 블록체인 기반 서비스 구축을 제공한다.<br/>
                대표적인 DAPP 솔루션 구축사례로 FK COIN을 들 수 있다.
              </div>
              <div className="content-image desk">
                <img src={require('../../img/main/offering/content-02.png')}/>
              </div>
              <div className="content-image mobile">
                <img src={require('../../img/main/offering/content-02-m.png')}/>
              </div>
              <div className="content-summary-text">
                FK ASSET은 4차 산업혁명의 핵심기술로 부각되는 블록체인 기술을 실생활에서
                스마트하게 다각적으로 사용 할 수 있을 뿐만 아니라 현실적 결제, 교환 수단으로써의 가치를 실현합니다.
              </div>
              <div className="content-text">
                FK코인을 보관할 수 있는 지갑과 호텔, 쇼핑몰에서 지갑으로 간편하게 결제할 수 있는 시스템을 제공하여 실제 사용할 수 있는 코인이라는 가치를 부여하였고, 
                국내 및 해외 거래소에 상장하여 누구나 쉽게 FK코인을 구매하여 이용할 수 있도록 하였습니다.
              </div>
            </section>

            {/* 블록체인 콜드월렛 서비스 */}
            <section id='coldwallet'>
              <div style={{height: '50px'}}></div>
              <div className="content-title-text">블록체인 콜드월렛 서비스</div>
              <div className="content-line"></div>
              <div style={{height: '20px'}}></div>
              <div className="content-image desk">
                <img src={require('../../img/main/offering/content-03.png')}/>
              </div>
              <div className="content-image mobile">
                <img src={require('../../img/main/offering/content-03-m.png')}/>
              </div>
              <div className="content-summary-text">
                블랍스 하이브리드 콜드 월렛은 기존 핫 월렛의 해킹 위험성과 <br/>
                콜드 월렛의 불편함을 보완한 3세대 카드형 지갑입니다.
              </div>
              <div className="content-text">
                2018년 출시하여 현재 많은 유저들이 비트코인, 이더리움 등의 자산을 안전하게 보관하기 위하여 이용하고 있으며, 
                사용이 편리하고 부담스럽지 않은 가격으로 공급하고 있어 이용자들로부터 큰 호응을 얻고 있습니다. 
              </div>
            </section>

            {/* 블록체인 서비스 운영 */}
            <section id='service'>
              <div style={{height: '50px'}}></div>
              <div className="content-title-text">블록체인 서비스 운영</div>
              <div className="content-line"></div>
              <div style={{height: '20px'}}></div>
              <div className="content-summary-text">
                블랍스는 코인결제대행 등 다양한 블록체인 기반 서비스들을 준비하고 있다.
              </div>
              <div className="content-text">
                스마트폰 확산, 다양한 인증방식 등장으로 모바일 간편결제 시장이 빠른 속도로 성장하고 있는 간편결제 시장에서 암호화폐의 역할은 크다. <br/>
                특히 암호화폐 결제는 점포를 운영하는 사업자나, 매장 점주에게 가장 큰 부담인 수수료 문제를 해결해 줄 수 있다는 것과 외국인 관광객의 국내 소비를 
                촉진하거나 과거 “천송이 코트” 문제와 같이 해외 고객들이 국내 쇼핑몰을 이용할 때 겪는 불편을 크게 줄일 수 있을 것으로 기대된다.
              </div>
              <div className="content-image desk">
                <img src={require('../../img/main/offering/content-04.png')}/>
              </div>
              <div className="content-image mobile">
                <img src={require('../../img/main/offering/content-04-m.png')}/>
              </div>
              <div className="content-text">
                블랍스 결제 서비스는 각 코인 별 기준이 되는 거래소의 실시간 시세를 반영하여 결제 시에는 사용자가 원화를 입력하여 결제를할 수 있도록 한다. <br/>
                또한 결제 처리 시 일정 금액 이하의 경우 트랜잭션 생성과 동시에 결제완료 처리를 하기 때문에 사용자는 실시간으로 결제가 완료되는 것을 체감할 수 있다.<br/> 
                블록체인의 컨펌 없이 결제완료 처리를 하기 때문에 플랫폼에서는 이상상황을 상시 모니터링 하며, 
                지정된 시간 내 컨펌이 되지 않거나 공격성 트랜잭션이 발생하는 경우 즉시 이상상황을 가맹점으로 통보하여 
                결제 문제에 대응할 수 있는 대응 체계를 가지고 있다.
              </div>
            </section>

            {/* 플랫타 프로젝트 */}
            <section id='flata-project'>
              <div style={{height: '50px'}}></div>
              <div className="content-title-text">플랫타 프로젝트</div>
              <div className="content-line"></div>
              <div style={{height: '10px'}}></div>
              <div className="content-image desk">
                <img src={require('../../img/main/offering/content-05.png')}/>
              </div>
              <div className="content-image mobile">
                <img src={require('../../img/main/offering/content-05-m.png')}/>
              </div>
              <div className="content-text">
                공감은 “정서적 산소＂라고 할 정도로 우리에게 중요한 감정입니다. 우리는 공감 덕분에 타인을 이해하고 타인과 연결됩니다. <br/>
                수많은 암호화폐 재단들이 암호화폐 생태계를 확장하기 위하여 많은 노력을 하였지만 변동성이 많은 암호화폐의 성격과 재단은 역량 미달로 
                결제와 생태계 확장의 한계를 보이고 문제점 노출로 부정적인 이미지가 있는 것이 오늘날의 암호화폐 시장의 현실입니다. <br/>
                플랫타 프로젝트는 이런 문제를 해결하기 위하여 기존에 사업을 영위하고 있는 견실한 기업들을 선별하여 실생활에 바로 활용 가능한 암호화폐 생태계를
                구축하는 사업입니다. 코인을 담을 수 있는 DAPP 지갑을 오픈하였고, DAPP내에서 암호화폐 간 교환이나 이벤트를 진행합니다.
              </div>
            </section>

            <div className="page-top-image-wrapper">
              <a href='#'>
                <img src={require('../../img/icon/page-up.png')} className="page-top-image"/>
              </a>
            </div>

          </div>
        </div>
      </section>
    );
  }
}
