import "react-app-polyfill/ie9"; // For IE 9-11 support
// import "react-app-polyfill/ie11"; // For IE 11 support
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { RenderAfterNavermapsLoaded } from "react-naver-maps";

ReactDOM.render(
  <BrowserRouter>
    <RenderAfterNavermapsLoaded
      ncpClientId={"r06yvi25vl"}
      error={<p>Maps Load Error</p>}
      loading={<p>Maps Loading...</p>}
      submodules={["geocoder"]}>
      <App />
    </RenderAfterNavermapsLoaded>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
