import React, { Component } from "react";
import { Link } from "react-router-dom";
import { httpGet, httpUrl } from "../../api/httpclient";
import string_kr from "../../string_kr";
import { Icon } from "antd";

export default class Home extends Component {
  state = {
    boardListNote: [],
    boardListEvnt: [],
    selectedRow: parseInt(this.props.match.params.idx)
      ? parseInt(this.props.match.params.idx)
      : 0
  };

  getBoardList = () => {
    httpGet(httpUrl.boardList, [10, 1, 'NOTE'], {}).then(res => {
      this.setState({
        boardListNote: res.data.data
      });
    });
    httpGet(httpUrl.boardList, [10, 1, 'EVENT'], {}).then(res => {
      this.setState({
        boardListEvnt: res.data.data
      });
    });
  };

  componentDidMount() {
    this.getBoardList();
  }

  render() {
    return (
      <section>
        <div style={{height: '220px'}}>
          <div className="container">
            <div className="title-text">
              뉴스룸 News room
            </div>
            <div className="subtitle-text">
              블랍스 소식
            </div>
            <div style={{marginTop: '10px', marginLeft: '-8px'}}>
            </div>
          </div>
        </div>
        
        <div className="content-container">
          <div className="container" style={{minHeight: '600px', paddingTop: '10px', paddingBottom: '150px'}}>
          
            {/* 공지 */}
            <div className="content-title-text">공지사항</div>
            <div className="content-line"></div>

            <div className="board">
              {this.state.boardListNote.map(item => (
                <div className="board-item">
                  <div
                    className="question pointer"
                    onClick={() => {
                      this.setState({
                        selectedRow:
                          this.state.selectedRow === item.idx ? 0 : item.idx
                      });
                    }}>
                    <div className="question-wrap flex-row">
                      <div className="icon">
                        {this.state.selectedRow === item.idx ? (
                          <Icon type="up" style={{ color: "#3afdfb" }} />
                        ) : (
                          <Icon type="down" />
                        )}
                      </div>
                      <div className="article">
                        <span>{`${
                          item.title
                        }`}</span>
                      </div>
                    </div>
                  </div>
                  {this.state.selectedRow === item.idx && (
                    <div className="answer">
                      <div>
                        <span>{item.title}</span>
                      </div>
                      <span>{item.content}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
            
            {/* 이벤트 */}
            <div className="content-title-text">이벤트</div>
            <div className="content-line"></div>

            <div className="board">
              {this.state.boardListEvnt.map(item => (
                <div className="board-item">
                  <div
                    className="question pointer"
                    onClick={() => {
                      this.setState({
                        selectedRow:
                          this.state.selectedRow === item.idx ? 0 : item.idx
                      });
                    }}>
                    <div className="question-wrap flex-row">
                      <div className="icon">
                        {this.state.selectedRow === item.idx ? (
                          <Icon type="up" style={{ color: "#3afdfb" }} />
                        ) : (
                          <Icon type="down" />
                        )}
                      </div>
                      <div className="article">
                        <span>{`${
                          item.title
                        }`}</span>
                      </div>
                    </div>
                  </div>
                  {this.state.selectedRow === item.idx && (
                    <div className="answer">
                      <div>
                        <span>{item.title}</span>
                      </div>
                      <span>{item.content}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>

          </div>
        </div>
      </section>
    );
  }
}
