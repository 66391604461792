import React, { Component } from "react";
import { Menu, Icon, Dropdown, Modal, Button } from "antd";
import { Menu as MobileMenu, NavBar } from "antd-mobile";
import { Link, withRouter } from "react-router-dom";
import string_kr from "../string_kr";

const { SubMenu } = Menu;

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
      drawerOpen: false,
      modalVisible: false
    };
  }

  handleClick = (e) => {
    this.setState({
      current: e.key
    });
  };

  componentDidMount() {}

  render() {
    return (
      <nav className="navigation">
        <div className="navigation-container desk">
          <div style={{ display: "inline-block" }}>
            <Link to="/">
              <img src={require("../img/icon/blobs.png")} />
            </Link>
          </div>
          <div
            style={{
              display: "inline-block",
              float: "right",
              marginTop: "-8px"
            }}>
            <Menu
              className="navigation-list"
              mode="horizontal"
              style={{
                backgroundColor: "transparent",
                color: "#fff",
                borderBottomWidth: 0
              }}
              onClick={this.handleClick}
              selectedKeys={[this.state.current]}>
              <Menu.Item style={{ marginLeft: "120px" }}>
                <Link to="/Offering">{string_kr.menuOffering}</Link>
              </Menu.Item>
              {/* <Menu.Item style={{ marginLeft: "120px" }}>
                <Link to="/NewsRoom">{string_kr.menuNewsRoom}</Link>
              </Menu.Item> */}
              <Menu.Item style={{ marginLeft: "120px" }}>
                <Link to="/CompanyInfo">{string_kr.menuCompanyInfo}</Link>
              </Menu.Item>
              <SubMenu
                style={{ marginLeft: "120px" }}
                title={
                  <div className="submenu-title-wrapper">
                    <span>{string_kr.menuLink}</span> <Icon type="caret-down" />
                  </div>
                }>
                <Menu.Item>
                  <Link
                    onClick={() => {
                      window.open("https://card.blobs.kr");
                    }}>
                    {string_kr.menuLink1}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    onClick={() => {
                      window.open("https://solution.blobs.kr");
                    }}>
                    {string_kr.menuLink2}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    onClick={() => {
                      this.setState({ modalVisible: true });
                    }}>
                    {string_kr.menuLink6}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    onClick={() => {
                      window.open("https://coin.blobs.kr");
                    }}>
                    {string_kr.menuLink3}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    onClick={() => {
                      window.open("https://flata.io/");
                    }}>
                    {string_kr.menuLink4}
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    onClick={() => {
                      window.open("https://flata.exchange");
                    }}>
                    {string_kr.menuLink5}
                  </Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                onClick={() => {
                  window.open("http://flata.io");
                }}>
                <span>{string_kr.menuFlata}</span>
              </Menu.Item>
            </Menu>
          </div>
        </div>
        <div className="mobile navigation-container">
          <NavBar
            className="navbar"
            leftContent={
              <Link to="/">
                <img src={require("../img/icon/blobs.png")} />
              </Link>
            }
            rightContent={
              <div
                className="pointer"
                onClick={() => {
                  this.setState({
                    drawerOpen: !this.state.drawerOpen
                  });
                }}>
                <Icon type="menu" />
              </div>
            }
            mode="light"
            style={{
              position: "relative",
              color: "#fff"
            }}></NavBar>
          {this.state.drawerOpen && (
            <div className="drawer">
              <Menu
                mode="inline"
                style={{
                  backgroundColor: "transparent",
                  color: "#fff",
                  borderBottomWidth: 0
                }}
                onClick={(e) => {
                  this.setState(
                    {
                      drawerOpen: false
                    },
                    () => {
                      this.handleClick(e);
                    }
                  );
                }}
                selectedKeys={[this.state.current]}>
                <Menu.Item style={{ marginLeft: "120px" }}>
                  <Link to="/Offering" className="white-text">
                    {string_kr.menuOffering}
                  </Link>
                </Menu.Item>
                {/* <Menu.Item style={{ marginLeft: "120px" }}>
                  <Link to="/NewsRoom" className="white-text">
                    {string_kr.menuNewsRoom}
                  </Link>
                </Menu.Item> */}
                <Menu.Item style={{ marginLeft: "120px" }}>
                  <Link to="/CompanyInfo" className="white-text">
                    {string_kr.menuCompanyInfo}
                  </Link>
                </Menu.Item>
                <SubMenu
                  style={{ marginLeft: "120px" }}
                  title={
                    <div className="submenu-title-wrapper">
                      <span>{string_kr.menuLink}</span>{" "}
                      <Icon type="caret-down" />
                    </div>
                  }>
                  <Menu.Item>
                    <Link
                      onClick={() => {
                        window.open("https://card.blobs.kr");
                      }}>
                      {string_kr.menuLink1}
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      onClick={() => {
                        window.open("https://solution.blobs.kr");
                      }}>
                      {string_kr.menuLink2}
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      onClick={() => {
                        this.setState({ modalVisible: true });
                      }}>
                      {string_kr.menuLink6}
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      onClick={() => {
                        window.open("https://coin.blobs.kr");
                      }}>
                      {string_kr.menuLink3}
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      onClick={() => {
                        window.open("https://flata.io/");
                      }}>
                      {string_kr.menuLink4}
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      onClick={() => {
                        window.open("https://flata.exchange");
                      }}>
                      {string_kr.menuLink5}
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item
                  onClick={() => {
                    window.open("http://flata.io");
                  }}>
                  <span>{string_kr.menuFlata}</span>
                </Menu.Item>
              </Menu>
            </div>
          )}
        </div>
        <Modal
          title={<span>블랍스 코드 </span>}
          visible={this.state.modalVisible}
          onCancel={() => {
            this.setState({ modalVisible: false });
          }}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                this.setState({ modalVisible: false });
              }}>
              확인
            </Button>
          ]}>
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            서비스 준비중입니다.
          </div>
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            <img
              src={require("../img/main/link/blobscode.png")}
              className="header-blobscode-img"></img>
          </div>
        </Modal>
      </nav>
    );
  }
}

export default withRouter(Navigation);
