import React, { Component } from "react";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Content from "./components/Content";
import { Spin } from "antd";

class App extends Component {
  render() {
    return (
      <div className="app">
        <div
          id="loadingSpinner"
          style={{
            display: "none",
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            left: 0,
            textAlign: "center",
            zIndex: 99999
          }}
        >
          <Spin style={{ position: "absolute", top: "50%", zIndex: 99999 }} />
        </div>
        <div className="app-container">
          <Header />
          <Content />
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
