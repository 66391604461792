import React, { Component } from "react";
import { Link } from "react-router-dom";
import { httpGet, httpUrl } from "../../api/httpclient";
import string_kr from "../../string_kr";

export default class Home extends Component {
  render() {
    return (
      <section>
        <div className="non-height-container" style={{color: '#fff', textAlign: 'center'}}>
          <div className="home-title-text" style={{fontSize: '48px'}}>BLOBS Blockchain Technology</div>
          <div className="home-title-text2" style={{fontSize: '23px', lineHeight: '33px'}}>
            고객의 성공과 함께 성장하는 기업<br/>
            (주)블랍스
          </div>
        </div>
      </section>
    );
  }
}
