import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Home from '../pages/main/Home'
import Offering from '../pages/main/Offering'
import NewsRoom from '../pages/main/NewsRoom'
import CompanyInfo from '../pages/main/CompanyInfo'
export default class Content extends Component {
  render() {
    return (
      <div id="content">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Offering" component={Offering} />
          <Route exact path="/NewsRoom" component={NewsRoom} />
          <Route exact path="/CompanyInfo" component={CompanyInfo} />
        </Switch>
      </div>
    );
  }
}
